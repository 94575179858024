import React, { useEffect, useState } from "react";
import SEO from "../../components/seo";
import ContactForm from "../../components/ContactForm";

type Props = {};

const DriftContact = (props: Props) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  } else {
    return (
      <div className="text-black100 max-w-7xl px-6 lg:px-10 mx-auto my-16 ">
        <SEO title="Contact Support - Drift" lang="en" />
        <h1 className="text-2xl md:text-3xl font-semibold ">Contact Support</h1>
        <div className="max-w-xl mt-8">
          <ContactForm />
        </div>
      </div>
    );
  }
};

export default DriftContact;
